import { Link } from 'react-router-dom'

const Boilerplates = () => {
  return (
    <>
      <h1>Boilerplates</h1>
      <div className="d-flex">
        <div className=" p-4 border-right">
          <ul className="list-unstyled">
            <li>
              <h4>HTML</h4>
              <ul className="list-unstyled">
                <li><Link className="nav-link" to={"indexhtml" }>index.html</Link></li>
              </ul>
            </li>
            
            <li>
              <h4>React JS</h4>
              <ul className="list-unstyled">
                <li><Link className="nav-link">React Basic Component</Link></li>
              </ul>
            </li>
            <li>
              <h4>Tools</h4>
              <ul className="list-unstyled">
                <li><Link className="nav-link" to={'../code-formatter' }>Code Formatter</Link></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="flex-grow-1">
          Content
        </div>
      </div>
    </>
  )
}
export default Boilerplates