import { useEffect, useState } from "react"

const IndexHtml = () => {
  const [styleCode, setStyleCode] = useState('')
  const formatColor = { tag: 'blue', quote: 'red', string: 'brown', attr: 'aqua' }

  const code = `&lt;!DOCTYPE html&gt;
&lt;html lang=&quot;en&quot;&gt;
&lt;head&gt;
    &lt;meta charset=&quot;utf-8&quot; /&gt;
    &lt;meta name=&quot;viewport&quot; content=&quot;width=device-width, initial-scale=1.0, user-scalable=0, minimal-ui, viewport-fit=cover&quot; /&gt;
    &lt;meta name=&quot;apple-mobile-web-app-title&quot; content=&quot;CodePx&quot;&gt;
    &lt;meta name=&quot;mobile-web-app-capable&quot; content=&quot;yes&quot;&gt;
    &lt;meta name=&quot;apple-mobile-web-app-capable&quot; content=&quot;yes&quot;&gt;
    &lt;meta name=&quot;application-name&quot; content=&quot;CodePx&quot;&gt;
    &lt;meta name=&quot;description&quot; content=&quot;Web development tools and resources. Learn and implement&quot; /&gt;
    &lt;link rel=&quot;manifest&quot; href=&quot;/site.webmanifest&quot;&gt;
    &lt;link rel=&quot;apple-touch-icon&quot; href=&quot;/apple-touch-icon.png&quot;&gt;
    &lt;title&gt;CodePx :: Learn and Develop - Quick quide to web development&lt;/title&gt;
    &lt;base href=&quot;/&quot;&gt;
    &lt;link rel=&quot;dns-prefetch&quot; href=&quot;https://fonts.gstatic.com/&quot;&gt;
    &lt;link rel=&quot;dns-prefetch&quot; href=&quot;https://fonts.googleapis.com/&quot;&gt;
    &lt;link rel=&quot;preconnect&quot; href=&quot;https://fonts.googleapis.com&quot;&gt;
    &lt;link rel=&quot;preconnect&quot; href=&quot;https://fonts.gstatic.com&quot; crossorigin&gt;
    &lt;link href=&quot;https://fonts.googleapis.com/css2?family=Lato:wght@100;400;500;700&amp;display=swap&quot; rel=&quot;stylesheet&quot;&gt;
    &lt;link href=&quot;https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css&quot; rel=&quot;stylesheet&quot; integrity=&quot;sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH&quot; crossorigin=&quot;anonymous&quot;&gt;
    
    &lt;link href=&quot;style.css&quot; rel=&quot;stylesheet&quot; type=&quot;text/css&quot;&gt;
&lt;/head&gt;
&lt;body&gt;
    &lt;div id=&quot;root&quot;&gt;&lt;/div&gt;
    &lt;script src=&quot;https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js&quot; integrity=&quot;sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz&quot; crossorigin=&quot;anonymous&quot;&gt;&lt;/script&gt;

&lt;/body&gt;
    
&lt;/html&gt;`
  useEffect(() => {
    
    const formatContent = String(code).replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&quot;/g, '"')

    setStyleCode(code
      .replace(/(&lt;\w(.*?)\s)/g, (match) => { return `<span style="color:aqua">${match}</span>` }) //tags
      .replace(/&quot;(.*?)&quot;/g, (match) => { return `<span style="color:brown">${match}</span>` }) //attr value
      .replace(/&quot;/g, '<span style="color:red">"</span>')
      .replace(/(&lt;\/\w(.*?)&gt;)|(\/&gt;)/g, (match) => { return `<span style="color:aqua">${match}</span>` }) //tags
    )
  }, [])
  return (
    <>
    <h1>index.html</h1>
      <pre className="p-3 border text-bg-dark" style={{minHeight:'2rem'} }>
        <code dangerouslySetInnerHTML={{ __html: styleCode } }>
          
        </code>
      </pre>
    </>
  )
}
export default IndexHtml