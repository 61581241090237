import { Link } from 'react-router-dom'

const Nav = () => {
  

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand" to="">
            <img src="codepx_logo.svg" width="125" height="77" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to={ '/landing-pages'} className="nav-link">Landing Pages</Link>
              </li>
              <li className="nav-item">
                <Link to={'/boilerplates'} className="nav-link">Boilerplates</Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  More...
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="#">index.html</a></li>
                  <li><a className="dropdown-item" href="#">React App</a></li>
                  <li><a className="dropdown-item" href="#">.NET Core WebApi</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Nav
